import { FC, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { TCountertop, TMain } from '../../../types';
import { Paper } from '../../common/paper';
import { Subtitle } from '../controls/subtitle';
import { TopSelector } from '../controls/top-selector';
import { Edges } from './edges';
import { Sizes } from './sizes';
import { Panels } from './pannels';
import { TopLRSelector } from '../controls/top-LR-selector';
import { H } from '../../common/H';
import { TopImage } from './top-image';
import { InfoCard } from './info-card';
import { Switches } from './switches';
import { PreviewReadonly } from './preview';
import { ShowHideTop } from '../controls/show-hide-top';
import { useMobile } from '../../../hooks/useMobile';
import s from './style.module.css';
import { ProductOptionsMenuCountertop } from '../product-options-menu-countertop';
import { SelectWashingCountertop } from '../controls/select-washing-countertop';
import { SelectCookingCountertop } from '../controls/select-hob-panel-contertop';
import { Button } from '../controls/button';

export const Countertop: FC<{
  countertop: TCountertop;
  index: number;
  pathname: string;
}> = ({ countertop, index, pathname }) => {
  const formik = useFormikContext<TMain>();
  const { values, setFieldValue } = formik;
  const { isMobile, isReadOnly } = values.settings;
  const { isActive } = countertop;
  const { countertops } = values.calculator;

  // reinvoke
  useMobile(formik);

  return (
    <Paper>
      {isReadOnly && (
        <H>
          <Subtitle label="Столешница" />
        </H>
      )}

      {!isReadOnly && (
        <>
          <Subtitle
            label={`Основная часть ${
              countertops.length > 1 ? `#${index + 1}` : ``
            }`}
          />
          <H isMobile={isMobile}>
            {isMobile && <ShowHideTop pathname={pathname} />}

            {/* Отрефакторено */}
            <TopSelector
              pathname={pathname}
              countertop={countertop}
              index={index}
            />

            {/*  */}
            <TopLRSelector pathname={pathname} />
            {!isMobile && <ShowHideTop pathname={pathname} />}
            {!!index && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginLeft: 20,
                }}
                data-testid="remove-countertop"
              >
                <Button
                  type="plus-red"
                  onClick={() => {
                    const newArray = [...countertops];
                    newArray.splice(index, 1);
                    setFieldValue('calculator.countertops', newArray);
                  }}
                />
                Удалить столешницу
              </div>
            )}
          </H>
        </>
      )}

      <div className={s.countertop} data-testid="countertop">
        <TopImage countertop={countertop} />
        <div className={s.infoCardOverlay}>
          <InfoCard />
        </div>
        <Panels countertop={countertop} pathname={pathname} />
        <Edges countertop={countertop} />
        <Sizes countertop={countertop} pathname={pathname} />
        <Switches countertop={countertop} pathname={pathname} />

        {!isMobile && isActive && (
          <>
            <SelectWashingCountertop
              product="countertop"
              countertop={countertop}
              pathname={pathname}
            />
            <SelectCookingCountertop
              countertop={countertop}
              pathname={pathname}
            />
          </>
        )}
      </div>

      {isReadOnly && (
        <PreviewReadonly product="countertop" pathname={pathname} />
      )}
      {!isReadOnly && (
        <ProductOptionsMenuCountertop
          countertop={countertop}
          pathname={pathname}
          index={index}
        />
      )}
    </Paper>
  );
};
